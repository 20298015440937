export const mainColors = {
  white: '#FFFFFF',
  darkBlue: '#002375',
  blue: '#2081FA',
  neonBlue: '#2081FA',
  lightBlue: '#F0F8FA',
  footerBlue: '#0363E0',
  darkGray: '#333D49',
  lighterGray: '#373F41',
  transparentGray: '#737B7D',
  lightBackground: '#FAFAFA',
  dashboardBlue: '#F4F7Fe',
  dashboardDarkBlue: '#1B2559'
}

export const menuColors = {
  gray: '#454D4F',
  neonBlue: '#2081FA',
  logoBarGray: '#DCDCDC',
  logoBarWhite: '#FFFFFF',
  logoTextGray: '#9D9D9D',
  logoTextWhite: '#FFFFFF',
}

export const sizes = {
  defaultContainer: 1400,
}

export const breakPoints = {
  xs: '768px',
  sm: '992px',
  md: '1200px',
}

export const dashboard = {
  leftMargin: '35px'
}

