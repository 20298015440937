import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { CustomPage } from './components/CustomPage'
import 'bootstrap/dist/css/bootstrap.min.css'

const queryClient = new QueryClient()

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomPage />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
