import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import * as qs from 'query-string'
import Dropdown from './Dropdown'
import { sendRequest, getLink, postToken } from './api'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Header } from './Header'

import dots from '../assets/dots.svg'
import abstractFrame from '../assets/abstract-frame.svg'
import background from '../assets/sky-building.png'
import { mainColors } from './constants'

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`

const StyledContainer = styled(Container)`
background: ${mainColors.lightBlue};
color: ${mainColors.darkGray};
background-image: url(${background});
background-repeat: no-repeat;
background-size: cover;
`

const StyledRow = styled(Row)`
background: ${mainColors.lightBlue};
color: ${mainColors.darkGray};
background-image: url(${background});
background-repeat: no-repeat;
background-size: cover;
`

export const CustomPage = () => {
  const location = window.location.search
  const loc = qs.parse(location)

  const [text, setText] = useState(null)
  const [tokenText, setTokenText] = useState(false)
  const [dropdownValue, setSelectValue] = useState(null)
  const onChangeValue = (options) => {
    setSelectValue(options.value)
  }

  const { mutate: sendToken, isLoading: isLoadingSendToken } = useMutation(postToken, {
    onSuccess: (response) => {
      setTokenText(true)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const { mutate: obtainLink, isLoading: isLoadingObtainLink } = useMutation(getLink, {
    onSuccess: (response) => {
      setText(response.data.url)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const { mutate: updateData, isLoading: loadingPost } = useMutation(sendRequest, {
    onSuccess: (response) => {
      obtainLink(response)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const handleSubmit2 = (e) => {
    e.preventDefault()
    updateData(dropdownValue)
  }

  const buttonText = loadingPost ? 'loading' : 'Generate link'

  const refreshPage = () => {
    window.location.reload()
  }

  const resetToken = () => {
    window.location.href = 'http://hack.react-media.ro'
  }

  React.useEffect(() => {
    if(loc.temp_token) {
      sendToken(loc.temp_token)
    }
  }, [loc.temp_token, sendToken])

  return (
    <>
    <Header />
    <StyledContainer>
      <StyledRow>
        <Col>
          
          {loc.temp_token ? (
            <div style={{ margin: '50px' }}>
              <div>The token is: <div style={{wordWrap: 'break-word'}}>{loc.temp_token}</div></div>
              <br />
              <br />
              <div>
                {isLoadingSendToken ? (
                  <div>Sending Token...</div>
                ) : (
                  tokenText && 'The token has been sent!'
                )}
              </div>
              <br />
              <br />
              <Button type="button" onClick={() => resetToken()}>Restart Process</Button>
            </div>
          ) : (
            <div style={{ margin: '50px' }}>
              <form onSubmit={handleSubmit2}>
                <div style={{ marginBottom: '20px' }}>1. Please select a bank and hit generate to grant access on account:</div>
                <Dropdown
                  onChange={onChangeValue}
                />
                <StyledFlex>
                  <Button style={{ marginTop: '20px' }} type="submit">{buttonText}</Button>
                </StyledFlex>
              </form>
              <br />
              <br />
              <br />
              <br />

              <div>
                {(loadingPost || isLoadingObtainLink) ? (
                  <div>Generating login link...</div>
                ) : (
                  text && (
                    <div>
                      <div>2. Use Link: <a href={text}>{text}</a></div>
                      <br />
                      <br />
                      <div>or button <Button onClick={() => window.location.href = text}>Login</Button></div>
                      <br />
                      <br />
                    </div>
                  )
                )}
              </div>
              <br />
              <br />
              <br />
              <br />
              <hr />
              <Button type="button" onClick={refreshPage}>Reset Page</Button>
            </div>
          )}
        </Col>
      </StyledRow>
    </StyledContainer>
    </>
  )
}
