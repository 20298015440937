import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import Logo from './Logo'

export const Header = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <Logo
              color="blue"
              textColor="gray"
              separatorColor="gray"
              showText
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Container>
    </Navbar>
  )
}

