import React from 'react'
import styled from 'styled-components'
import { menuColors } from './constants'

import { ReactComponent as BlueLogo } from '../assets/Logo.svg'
import { ReactComponent as WhiteLogo } from '../assets/LogoWhite.svg'
import { ReactComponent as MinLogo } from '../assets/LogoMin.svg'

const LogoSvg = {
  blue: <BlueLogo />,
  white: <WhiteLogo />,
  min: <MinLogo />
}

const LogoTextColor = {
  gray: menuColors.logoTextGray,
  white: menuColors.logoTextWhite,
}

const barTextColor = {
  gray: menuColors.logoBarGray,
  white: menuColors.logoBarWhite,
}

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  svg {
    margin-top: -6px;
  }
`

const LogoText = styled.div`
  font-weight: 300;
  font-size: 13px;
  margin-left: 14px;
  padding-left: 14px;
  color: ${({ $color }) => $color };
  position: relative;

  &:after {
    content: '';
    background: ${({ $barColor }) => $barColor };
    position: absolute;
    bottom: 0;
    left: 0;
    top: 6px;
    height: 28px;
    width: 1px;
  }
`

const Logo = (props) => {
  const { color, textColor, separatorColor, className, to, showText, onClick } = props

  return (
    <LogoWrapper to={to} className={className} onClick={onClick}>
      {LogoSvg[color]}
      {showText && (
        <LogoText
          $color={LogoTextColor[textColor]}
          $barColor={barTextColor[separatorColor]}
        >
          Money to Site <br />
          Faster. Easier
        </LogoText>
      )}
    </LogoWrapper>
  )
}

export default Logo

