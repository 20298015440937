import React from 'react'
import Select from 'react-select'

const options = [
  { value: 'bt_ro_aisp_sbx_#1.0', label: 'Banca Transilvania RO' },
  { value: 'cec_ro_aisp_sbx_#1.0', label: 'CEC Bank RO' },
  { value: 'erste_ro_aisp_sbx_#1.0', label: 'BCR ERSTE Group RO' }
]

const Dropdown = (props) =>  {

const { onChange } = props
  return (
    <Select
      options={options}
      onChange={onChange}
      placeholder="Select a bank"
    />
  )
}

export default Dropdown
