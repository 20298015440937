export const sendRequest = async (dropdownValue) => {
  return fetch('https://dev2-api.finqware.com/v1/sessions', {
    method: 'POST',
    body: JSON.stringify({
      client_id: "f3407724-e478-458c-bad1-5feda2f6a911",
      client_app_key: "MDAxNmxvY2F0aW9uIGZpbnF3YXJlCjAwMWFpZGVudGlmaWVyIGxvYW55LWZpbnEKMDAxNmNpZCBzY29wZT1zZXNzaW9uCjAwMzdjaWQgY2xpZW50X2lkPWYzNDA3NzI0LWU0NzgtNDU4Yy1iYWQxLTVmZWRhMmY2YTkxMQowMDJmc2lnbmF0dXJlINeW1gB7bZIUqu8Iq8s_Ebw5w5BUEqtc3P9AfR4oHS6wCg",
      skill: dropdownValue,
    }),
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then(response => response.json())
    .catch((error) => {throw error})
}

export const getLink = async (response) => {
  const { session_id, nonce } = response
  return fetch(`https://dev2-api.finqware.com/v1/sessions/${session_id}/steps`, {
    method: 'POST',
    body: JSON.stringify({
      "client_id": "f3407724-e478-458c-bad1-5feda2f6a911",
      "nonce": nonce,
      "skill": "bt_ro_aisp_sbx_#1.0",
      "step": "sca",
      "data": {
        "psu_redirect_link": "http://hack.react-media.ro"
      }
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json())
    .catch((error) => {throw error})
}

export const postToken = async (token) => {
  return fetch(`https://rbtgj2y6ij.execute-api.eu-central-1.amazonaws.com/v1/tokens`, {
    method: 'POST',
    body: JSON.stringify({
      "client_id": "f3407724-e478-458c-bad1-5feda2f6a911",
      "client_secret": "4c5f3526-a59a-4eaa-a170-28bdf39ee023",
      "temp_token": token
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
      'Access-Control-Allow-Credentials': 'true',
    },
  })
    .then((response) => response.json())
    .catch((error) => {throw error})
}


